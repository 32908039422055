import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import AppLayout from "./layouts/AppLayout";

import './assets/css/global-styles.css';

Vue.component('AppLayout', AppLayout);

Vue.config.productionTip = false
Vue.mixin({
  methods: {
    formatNumber(a) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      var h = formatter.format(a).split('$')
      return ' $ ' + ' ' + h[1];
    },
    reformatDate(d){
      if (d){
        var da = new Date(d);
        var day = da.getDate();
        var month = da.getMonth();
        var year = da.getFullYear();
        let dayfinal;
        let monthfinal;
        if (day < 10){
          dayfinal = '0'+day
        }else{
          dayfinal = day
        }
        if (month < 10){
          monthfinal = '0'+month
        }else{
          monthfinal = month
        }
        return dayfinal + '-' + monthfinal + '-' + year;
      }

    },

  }

})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
