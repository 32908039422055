import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
    isCompression: false
});
Vue.use(Vuex)


import auth from "../modules/auth";


export default new Vuex.Store({
    state: {
        draw: true,
        over: false,
        messages: []
    },
    plugins: [createPersistedState({
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key)
        }
    })],
    mutations: {
        setDraw(state) {
            state.draw = !state.draw;
        },
        setOver(state, over) {
            state.over = over;
        },
        setMessages(state, message){
            if(message === 'clear'){
                state.messages = [];
            }else{
                state.messages.push(message);
            }
        }
    },
    actions: {
        setDraw({commit}) {
            commit('setDraw')
        },
        setOver({commit}, over) {
            commit('setOver', over);
        },
        setMessages({commit}, message){
            commit('setMessages', message);
        }
    },

    modules: {
        auth
    },
    getters: {
        getDraw(state) {
            return state.draw
        },
        getOver(state) {
            return state.over;
        },
        getMessages(state){
            return state.messages;
        }
    }
})
