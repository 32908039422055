import router from "../router";
import store from '../store';

const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'http://localhost:4000';

const requestFetch = async (path, method,data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: method,
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}

export default {
    namespaced: true,
    state: {
        user: {},
        token: '',
        errors: []
    },
    mutations: {
        setUser (state, user){
            state.user = user
        },
        setToken (state, token){
            state.token = token;
        },
        setErrors(state, error){
            if (error == 'clear') {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        }
    },
    actions: {
        //SET ERRORS
        setUser({commit}, user) {
            commit('setUser', user)
        },
        //SET ERRORS
        setToken({commit}, token) {
            commit('setToken', token)
        },
        //SET ERRORS
        setErrors({commit}, error) {
            commit('setErrors', error)
        },

        async getUsers({ commit }) {
            commit('setErrors', 'clear');
            const response = await requestFetch('/api/users');
            return response.users;
        },



        // UPDATE USER
        async updateUser({ commit }, user) {
            const response = await requestFetch(`/api/users/usuario/${user.id}`, 'PUT',user);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async updatePerfil({ commit }, user) {
            const response = await requestFetch(`/api/users/perfil/${user.id}`, 'PUT',user);
            if (response.msg){
                return true
            }else{
                return false
            }
        },

        // DESACTIVE USER
        async desactiveUser({ commit }, user) {
            const response = await requestFetch(`/api/users/delete_user/${user.id}`,'PUT', user);
            if(response){
                return true
            }else{
                return false
            }
        },
        async getOne({ commit }, id) {
            const response = await requestFetch(`/api/users/find/${id}`,'GET');
            return response.users
        },

        // LOGIN
        async signin({ commit }, user) {
            const response = await requestFetch(`/api/auth/login`, 'POST',user);
            if (response.user){
                commit('setUser', response.user);
                commit('setToken', 'Bearer ' + response.access_token);
                return router.push('/');
            }else{
                return response.error
            }
        },
        async registerUser({ commit }, user) {

            const response = await requestFetch(`/api/auth/register`, 'POST',user);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        // VERYFY TOKEN
        async userme({ commit }) {
            let response;
            if(!store.getters["auth/getToken"]){
                commit('setUser', undefined);
            }else{
                response = await requestFetch(`/api/auth/me`,'GET');
                if(response.error) {
                    commit('setUser', undefined);
                    commit('setToken', undefined);
                }
                commit('setUser', response.user);
            }

            return response;

        },

        //LOG OUT
        async logout({ commit }) {
            commit('setUser', undefined);
            commit('setToken', undefined);
            await router.push('/login');
        },



    },
    getters: {
        getUser(state){
            return state.user;
        },
        getToken(state){
            return state.token;
        },
        getErrors(state){
            return state.errors;
        }
    }

}
